<template>
	<div class="movie-list" :class="noscroll ? 'noscroll' : ''">
		<div class="item" v-for="(item, index) in data" :key="index" @click="goDetails(item.vod_id, item.playIndex)">
			<div class="img-box">
                <LayImage :src="item.vod_pic"></LayImage>
				<div class="tag">{{item.vod_remarks}}</div>
				<div class="play-num" v-if="item.playIndex > 1">
					看至{{item.playIndex}}集
				</div>
			</div>
			<div class="name">{{item.vod_name}}</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config'
	export default {
		props: ['data','noscroll'],
		data() {
			return {
				imgUrl :config.imgUrl,
			};
		},
		mounted() {
		},
		methods: {
			goDetails(id, num) {
				if (num) {
					this.$router.push({
						path: '/player?id=' + id,
					})
				} else {
					this.$router.push({
						path: '/detail?id=' + id,
					})
				}
			},
		},
	}
</script>

<style lang="less">
	.play-num{
		position: absolute;
		top: 0;
		right: 0;
		color: #fff;
		font-size: 12px;
		background: @b-color;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		padding: 2px 3px;

	}
	.movie-list{
		display: flex;
		overflow-x: scroll;
		&.noscroll{
			overflow: auto;
			flex-wrap: wrap;
			.item{
				margin-right: 0px;
				width: 33.333333%;
				padding: 0 5px;
				box-sizing: border-box;
				margin-bottom: 10px;
				.img-box{
					width: 100%!important;
				}
				.name{
					width: 100%;
				}
			}
		}
		.item{
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
			}
		}
		.name{
			width: 110px;
			margin-top: 5px;
			padding: 0 5px;
			box-sizing: border-box;
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden;
			 text-overflow: ellipsis;
		}
	}
.img-box{
	width: 110px;
	height: 150px;
	background: #f5f5f5;
	border-radius: 10px;
	position: relative;
	.tag{
		position: absolute;
		bottom: 5px;
		right: 5px;
		color: #f5f5f5;
		font-size: 12px;
		padding: 2px 5px;
		border-radius: 5px;
		background-color: rgba(127, 127, 127, .8);
	}
	.img{
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
}
</style>
