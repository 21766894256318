<template>
    <img class="image" v-lazy="img" fit="contain" />
</template>

<script>
    import config from '@/config/index'
    export default {
        props: [
            'src'
        ],
        data() {
            return {
                img: ''
            }
        },
        created() {
            if (this.src.indexOf('upload/vod') > -1) {
                this.img = config.imgUrl + this.src;
            } else {
                this.img = this.src;
            }
        },
    }
</script>

<style lang="less" scoped>
.image{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
}
</style>