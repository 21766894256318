<template>
    <van-nav-bar
        class="nav-box"
        :title="title"
        left-text=""
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        />
</template>

<script>
    export default {
        props: ['title'],
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onClickRight() {},
        },
    }
</script>

<style lang="less" >
.nav-box{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
}
.van-nav-bar .van-icon{
    color: #000;
}
</style>