<template>
    <footer>
      <div class="footer">
        本网站提供新影视资源均系收集各大网站<br>本网站只提供web页面浏览服务<br>并不提供影片资源存储，也不参与任何视频录制、上传
<br>若本站收集的节目无意侵犯了贵司版权,邮箱地址来信<br>我们将在第一时间删除相应资源<br>
Copyright © 2023 v.muyys.cn All Rights Reserved .
      </div>

    </footer>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.footer{
  padding: 10px;
  font-size: 12px;
  text-align: center;
  color: #989898;
}
</style>