import axios from 'axios';
import config from './index'
import router from '@/router';
 
// 创建axios实例
const service = axios.create({
  baseURL: config.apiUrl, // api的base_url
  timeout: 5000 // 请求超时时间
});
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken();
    // }
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理，例如只返回data部分
    const res = response.data;
    return res;
  },
  error => {
    console.log('err' + error); // for debug
    // router.push({
    //   path: '/404'
    // });


    return Promise.reject(error);
  }
);
 
export default service;