<template>
    <div>
        <van-popup v-model="popValue" position="bottom" :style="{ height: '90%' }" closeable round @close="close">
            <div class="download-box" v-if="data">
                <div class="title">请选择下载的集数</div>
                <van-notice-bar
                    left-icon="volume-o"
                    text="点击复制链接，自行用浏览器或迅雷等下载"
                />
                <div class="list">
                    <template v-for="(item, inde) in data.split('#')">
                        <div class="item" v-clipboard:copy="item.split('$')[1]" v-clipboard:success="onCopy" v-clipboard:error="onError">
                            <div class="num">
                               {{ item.split('$')[0] }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: String,
                default: '',
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                popValue: false,
            }
        },
        watch: {
            value(e) {
                this.popValue = e;
            },
        },
        methods: {
            onCopy(name) {
                this.$toast('复制成功')
            },
            onError() {},
            close() {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="less" scoped>
.download-box {
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 10px 35px;

    .title {
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
        padding-top: 10px;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        .item {
            display: inline-block;
            padding: 0 5px;
            box-sizing: border-box;
            margin-bottom: 10px;
            .num {
                background: #f5f5f5;
                border-radius: 8px;
                font-size: 12px;
                padding: 10px;
            }
        }
    }
}

</style>