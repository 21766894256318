/* eslint-disable no-console */

import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('准备好了')
    },
    registered () {
      console.log('服务人员已注册.')
    },
    cached () {
      console.log('内容已缓存以供脱机使用')
    },
    updatefound () {
      console.log('正在下载新内容')
    },
    updated () {
      console.log('新内容可用；请刷新')
    },
    offline () {
      console.log('找不到internet连接。应用程序正在脱机模式下运行')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
// }
