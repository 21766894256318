<template>
    <div style="background: #fff;">
        <HeaderSearch v-model="searchText" rightTxt="取消" :focus="true" @rightClick="navBack" @handleEnter="handleEnter" @inputChange="inputChange">
        </HeaderSearch>
        <!-- 搜索结果 -->
        <div class="res-big-box" v-if="searchData.length > 0">
            <div class="search-res-box" v-for="(item, index) in searchData" :key="index">
                <div class="search-box">
                    <div class="res-movie-box">
                        <div class="movie-img" @click="$router.push('/detail?id=' + item.vod_id)">
                            <div class="img-tag-top">{{ item.vod_year }}</div>
                            <LayImage :src="item.vod_pic" />
                            <div class="img-tag-bottom">{{ item.vod_remarks }}</div>
                        </div>
                        <div class="movie-info">
                            <div class="name" @click="$router.push('/detail?id=' + item.vod_id)">{{ item.vod_name }}</div>
                            <div class="movie-tag">{{ item.vod_year }} / {{ typespan(item.type_id_1,item.type_id) }} / {{ item.vod_lang }}</div>
                            <div class="movie-tag">{{ item.vod_actor }}</div>
                            <div class="btn-box">
                                <div class="play-btn" @click="$router.push('/player?id=' + item.vod_id)">
                                    <van-icon class="icon" class-prefix="icon" name="playbofang5" size="14" />立即播放</div>
                                <div class="down-btn" @click="downClick(index)" v-if="item.vod_down_url">
                                    <van-icon class="icon" class-prefix="icon" name="playxiazai" size="14" />
                                    下载
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 集 -->
                <!-- <template v-if="playUrl.length > 1">
                    <div class="episode-list">
                        <div class="item" v-for="(item, index) in playUrl" :key="index" @click="$router.push(`/player?id=${item.vod_id}&num=${index + 1}`)">
                            <div class="child">{{ item.split('$')[0]  }}</div>
                        </div>
                    </div>
                </template> -->

            </div>


            <!-- <div class="search-list-box" v-if="searchData2.length > 0">
                <Card :title="['相关内容']">
                    <MovieList :data="searchData2"></MovieList>
                </Card>
            </div> -->

        </div>

        <div v-else>
            <!-- 历史搜索 -->
            <div class="history-search" v-if="searchHistory.length > 0">
                <div class="history-search-title">
                    历史搜索
                    <div class="clear-history" @click="clearHistory"><van-icon style="margin-right: 5px;"
                            name="delete-o" />清除</div>
                </div>
                <div class="history-search-items">
                    <div class="history-search-item" v-for="(item, index) in searchHistory" :key="index"
                        @click="historyClick(item)">
                        {{ item }}
                    </div>
                </div>
            </div>

                
            <div class="help-btn" @click="helpPop = true">
                <van-icon class="help-icon" class-prefix="icon" name="playliuyanjianyi" size="24" color="#fff" />求片留言
            </div>



            <Card :title="['电视剧', '电影', '综艺', '动漫']" @change="cardChange" :cardIndex="cardIndex">
                <van-swipe class="my-swipe" :loop="false" :show-indicators="false" ref="swiper" @change="swiperChange">
                    <van-swipe-item>
                        <div class="recommend">
                            <div class="recommend-items">
                                <div class="recommend-item" v-for="(item, index) in hotMoviesData" :key="index"
                                    @click="goDetails(item.vod_id)">
                                    <div class="recommend-item-img">
                                        <div class="tag" :class="index == 0 ? 'one' : index == 1 ? 'two' : 'three'">{{ index
                + 1 }}</div>
                                        <LayImage :src="item.vod_pic"></LayImage>
                                    </div>
                                    <div class="recommend-item-info">
                                        <div class="recommend-item-name">{{ item.vod_name }}</div>
                                        <div class="recommend-item-type">{{ item.vod_year }} / {{ typespan(item.type_id_1,
                item.type_id) }} / {{ item.vod_actor }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="recommend">
                            <div class="recommend-items">
                                <div class="recommend-item" v-for="(item, index) in dyData" :key="index"
                                    @click="goDetails(item.vod_id)">
                                    <div class="recommend-item-img">
                                        <div class="tag" :class="index == 0 ? 'one' : index == 1 ? 'two' : 'three'">{{ index
                + 1 }}</div>
                                        <LayImage :src="item.vod_pic"></LayImage>
                                    </div>
                                    <div class="recommend-item-info">
                                        <div class="recommend-item-name">{{ item.vod_name }}</div>
                                        <div class="recommend-item-type">{{ item.vod_year }} / {{ typespan(item.type_id_1,
                item.type_id) }} / {{ item.vod_actor }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="recommend">
                            <div class="recommend-items">
                                <div class="recommend-item" v-for="(item, index) in zyData" :key="index"
                                    @click="goDetails(item.vod_id)">
                                    <div class="recommend-item-img">
                                        <div class="tag" :class="index == 0 ? 'one' : index == 1 ? 'two' : 'three'">{{ index
                + 1 }}</div>
                                        <LayImage :src="item.vod_pic"></LayImage>
                                    </div>
                                    <div class="recommend-item-info">
                                        <div class="recommend-item-name">{{ item.vod_name }}</div>
                                        <div class="recommend-item-type">{{ item.vod_year }} / {{ typespan(item.type_id_1,
                item.type_id) }} / {{ item.vod_actor }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="recommend">
                            <div class="recommend-items">
                                <div class="recommend-item" v-for="(item, index) in dmData" :key="index"
                                    @click="goDetails(item.vod_id)">
                                    <div class="recommend-item-img">
                                        <div class="tag" :class="index == 0 ? 'one' : index == 1 ? 'two' : 'three'">{{ index
                + 1 }}</div>
                                        <LayImage :src="item.vod_pic"></LayImage>
                                    </div>
                                    <div class="recommend-item-info">
                                        <div class="recommend-item-name">{{ item.vod_name }}</div>
                                        <div class="recommend-item-type">{{ item.vod_year }} / {{ typespan(item.type_id_1,
                                            item.type_id) }} / {{ item.vod_actor }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </Card>
            <!-- 推荐 -->
        </div>


        <Down  :data="downUrlAry" v-model="downShow"
            @close="downShow = false">
        </Down>

        <!-- 求片留言  -->
        <van-popup v-model="helpPop" round >
            <div class="help-pop">
                <div class="help-pop-title">求片留言</div>
                <div class="help-pop-input">
                    <textarea v-model="helpText" placeholder="请输入留言内容" class="textarea"></textarea>
                    <input class="input" v-model="helpEamil" type="text" placeholder="如果需要通知你，请留下你的邮箱" />
                </div>
                <div class="help-pop-btn" @click="helpSubmit">提交</div>
            </div>
        </van-popup>

    </div>
</template>

<script>
import HeaderSearch from '@/components/HeaderSearch.vue';
import Down from '@/components/Down.vue';
export default {
    components: {
        HeaderSearch,
        Down,
    },
    data() {
        return {
            helpText: '',
            helpEamil: '',
            helpPop: false,
            downShow: false,
            searchText: '',
            searchHistory: [],
            hotMoviesData: [],
            dyData: [],
            cardIndex: 0,
            zyData: [],
            dmData: [],
            firstFocus: false,
            searchData: [],
            searchData2: [],
            playUrl: [],
            downUrlAry: '',
        }
    },
    activated() {
        this.searchText = '';
        this.clearData();
    },
    created() {
        this.firstChange();
        this.loadSearchHistory();

  


        // 电视剧
        this.$http('/api/advertisement/list', {
            params: {
                recommend: 1,
                type_id_1: 2,
            },
        }).then((res) => {
            this.hotMoviesData = res;
        })

        // 电影
        this.$http('/api/advertisement/list', {
            params: {
                recommend: 1,
                type_id_1: 1,

            },
        }).then((res) => {
            this.dyData = res;
        })

        // 综艺
        this.$http('/api/advertisement/list', {
            params: {
                recommend: 1,
                type_id: 3,
            },
        }).then((res) => {
            this.zyData = res;
        })

        // 动漫
        this.$http('/api/advertisement/list', {
            params: {
                recommend: 1,
                type_id: 4,
            },
        }).then((res) => {
            this.dmData = res;
        })
    },
    methods: {
        helpSubmit() {
            if (this.helpText == '') {
                this.$toast('请输入内容');
                return;
            } 

            this.$http.post('/api/help/message', {
                content: this.helpText,
                email: this.helpEamil,
            }).then((res)=> {
                this.$toast('提交成功,等待管理员添加');
                this.helpText = '';
                this.helpPop = false;
                this.helpEamil = '';
            })
        },
        // 下载
        downClick(index) {
            this.downShow = true;
            this.downUrlAry = this.searchData[index].vod_down_url;
        },
        historyClick(item) {
            this.searchText = item;
            this.handleSubmit();
        },
        goPlayer(id, num) {
            this.$router.push({
                path: '/player?id=' + id + '&num=' + num,
            })
        },
        inputChange(e) {
            this.searchText = e;
            if (e == '') {
                this.clearData();
            }
        },
        clearData() {
            this.searchData = [];
            this.searchData2 = [];
            this.playUrl = [];
        },
        handleEnter(e) {
            this.searchText = e;
            this.handleSubmit();
        },
        firstChange() {
            this.firstFocus = false; // 每次都要初始化 focus 属性
            setTimeout(() => {
                this.firstFocus = true; //自动获取焦点
            }, 0)
        },
        swiperChange(i) {
            this.cardIndex = i;
        },
        cardChange(i) {
            this.$refs.swiper.swipeTo(i);
        },
        navBack() {
            this.$router.go(-1);
        },
        goDetails(id) {
            this.$router.push({
                path: '/detail?id=' + id,
            })
        },
        typespan(s, t) {
            let index = s == 0 ? t : s;
            let txtAry = {
                1: '电影',
                2: '电视剧',
                3: '综艺',
                4: '动漫',
                5: '资讯',
            }
            return txtAry[index];
        },
        clearHistory() {
            this.searchHistory = [];
            this.saveSearchHistory();
        },
        // 处理搜索输入变化
        handleSearch() {
            // 这里可以根据需要添加防抖或节流处理
        },
        // 提交搜索
        handleSubmit() {
            this.clearData();
            if (this.searchText) {
                this.addSearchToHistory(this.searchText);
                // this.$router.push('/searchres?key=' + this.searchText)
                // this.searchText = ''; // 清空输入框

                this.$http('/api/movies/search', {
                    params: {
                        searchKey: this.searchText,
                    },
                }).then((res) => {
                    this.searchData = res.rows;
                    
                    if (res.rows.length > 1) {
                        this.searchData2 = Object.assign([], res.rows);
                        this.searchData2.splice(0, 1);
                    }
                    if (res.rows.length > 0) {
                        const url = res.rows[0].vod_play_url.split('$$$')[0];
                        this.playUrl = url.split('#')
                    }
                    if (res.rows.length == 0) {
                        this.$toast('没有找到喔!')
                    }
                })
            }
        },
        // 添加搜索到历史记录
        addSearchToHistory(query) {
            if (!this.searchHistory.includes(query)) {
                this.searchHistory.unshift(query);
                this.saveSearchHistory();
            }
        },
        // 从历史记录召回搜索
        recallSearch(query) {
            this.searchText = query;
        },
        // 移除指定的历史记录项
        removeFromHistory(index) {
            this.searchHistory.splice(index, 1);
            this.saveSearchHistory();
        },
        // 加载本地存储的历史记录
        loadSearchHistory() {
            const history = localStorage.getItem('searchHistory');
            if (history) {
                this.searchHistory = JSON.parse(history);
            }
        },
        // 保存历史记录到本地存储
        saveSearchHistory() {
            localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
        },
    },
}
</script>

<style lang="less" scoped>
.help-pop{
    padding: 20px;
    width: 300px;
    .input{
        width: 100%;
        border-radius: 8px;
        margin-top: 8px;
        outline: none;
        border: 1px solid #ccc;
        padding: 5px 10px;
    }
}
.textarea{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    resize: none;
}
.help-pop-btn{
    background: @b-color;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
    margin-top: 20px;
}
.help-pop-title{
    font-size: 16px;
    text-align: center;
    padding: 0 20px 20px;
}
.help-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    background: @b-color;
    color: #fff;
    margin: 20px auto;
    border-radius: 20px;
    .help-icon{
        margin-right: 10px;
    }
}
.episode-list {
    padding: 0 5px;
    white-space: nowrap;
    overflow: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
    .item {
        display: inline-block;
        min-width: 16.666666%;
        padding: 0 5px;
        box-sizing: border-box;
        text-align: center;

        .child {
            height: 40px;
            padding: 0 8px;
            border-radius: 8px;
            line-height: 40px;
            background: rgb(235, 235, 235);
            font-weight: bold;
            font-size: 14px;
            
        }
    }
}

.search-res-box {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 10px;

    .search-box {
        display: block;
    }

    .btn-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;

        .icon {
            margin-right: 8px;
        }

        .play-btn {
            flex: 1;
            background: linear-gradient(135deg, #bc95c6, #7dc4cc);
            padding: 8px 10px;
            color: #fff;
            border-radius: 50px;
            text-align: center;
        }

        .down-btn {
            padding: 8px 10px;
            background: rgb(235, 235, 235);
            margin-left: 10px;
            border-radius: 30px;
        }
    }

    .res-movie-box {
        display: flex;

        .movie-img {
            width: 90px;
            height: 120px;
            position: relative;

            .img-tag-top {
                position: absolute;
                top: 5px;
                left: 5px;
                color: #fff;
                font-size: 12px;
            }

            .img-tag-bottom {
                position: absolute;
                bottom: 5px;
                right: 5px;
                color: #fff;
                font-size: 12px;
            }
        }

        .movie-info {
            flex: 1;
            margin-left: 10px;

            .name {
                padding-bottom: 10px;
                font-weight: 600;
            }

            .movie-tag {
                font-size: 12px;
                color: #989898;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }
        }
    }
}

.recommend {
    .recommend-item-name {
        font-size: 14px;
    }

    .recommend-item {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        .recommend-item-img {
            width: 90px;
            height: 110px;
            margin-right: 10px;
            position: relative;

            .tag {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: rgba(0, 0, 0.5);
                color: #fff;
                font-weight: bold;
                font-size: 12px;
                padding: 5px;
                border-top-left-radius: 10px;
                border-bottom-right-radius: 10px;

                &.one {
                    background-color: #ff504d;
                }

                &.two {
                    background-color: #ff804d;
                }
            }
        }

        .recommend-item-type {
            color: #989898;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 267px;
            margin-top: 10px;
        }

        .recommend-item-info {
            flex: 1;
        }
    }

    .recommend-title {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

.history-search {
    padding: 0 10px;

    .history-search-title {
        font-size: 14px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .clear-history {
            color: #989898;
        }
    }

    .history-search-item {
        border-radius: 5px;
        margin-bottom: 8px;
        display: inline-block;
        padding: 8px 10px;
        background: #f5f5f5;
        margin-right: 8px;
        font-size: 14px;
    }
}

.res-big-box {
    padding: 10px;
    border-radius: 8px;
}

.search-box {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .search-items {
        display: flex;
        flex: 1;
        position: relative;

        .search-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            background: @w-color;
            color: #fff;
            height: 100%;
            font-size: 14px;
            padding: 0 20px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            display: flex;
            align-items: center;
            line-height: 1;
        }

        .search-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
        }
    }

    .cancel {
        font-size: 14px;
        color: #989898;
        margin-left: 10px;
    }

    .input {
        font-size: 14px;
        height: 30px;
        outline: none;
        border-radius: 50px;
        border: 1px solid #989898;
        width: 100%;
        padding-left: 40px;
    }
}
</style>