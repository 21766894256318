<template>
  <div class="warp-content">
    <HeaderSearch @inputClick="$router.push('/search')" :readonly="true" rightIcon="tosend" @rightClick="rightClick">
    </HeaderSearch>

    <div class="home-banner">
      <van-swipe class="banner" :autoplay="5000" indicator-color="white" @change="onChange">
        <van-swipe-item class="item" v-for="(item, index) in banner" :key="index" @click="goDetails(item.vod_id)">
          <LayImage :src="item.vod_pic_slide"></LayImage>

          <template v-if="item.vod_sub">
            <div class="banner-txt">{{ item.vod_sub }}</div>
          </template>
          <div class="banner-txt" v-else>{{ item.vod_name }} · {{ item.vod_remarks }}</div>
        </van-swipe-item>
        <template #indicator v-if="banner.length > 1">
          <div class="custom-indicator">{{ current + 1 }}/{{ banner.length }}</div>
        </template>
      </van-swipe>
    </div>


    <!-- <div class="hot-broadcast">
      <Card :title="['热播']">
        <MovieList :data="hotData"></MovieList>
      </Card>
    </div> -->
    <div class="hot-broadcast" v-if="historyData.length > 0">
      <Card :title="['观看记录']" rightType="more">
        <MovieList :data="historyData"></MovieList>
      </Card>
    </div>

    <div class="hot-broadcast">
      <Card :title="['正在热播']">
        <MovieList :data="dsjData" :noscroll="1"></MovieList>
      </Card>
    </div>

    <Footer></Footer>
    <!-- <van-popup v-model="searchPop" position="bottom" :style="{ height: '100%' }">
      <Search @navBack="navBack"></Search>
    </van-popup> -->

  </div>
</template>

<script>
import Search from './Search.vue';
import HeaderSearch from '@/components/HeaderSearch.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: {
    Search,
    HeaderSearch,
    Footer,
  },
  metaInfo() {
    return {
      title: '慕叶影视 - 最新热播剧集，免费高清在线观看',
      meta: [
        {
          name: 'description',
          content: '探索最新最热的电影与电视剧，慕叶影视为您提供高清流畅的观影体验，海量视频资源免费在线观看。立即加入，开启您的精彩影视之旅！'
        },
        {
          name: 'keywords',
          content: '慕叶影视, 免费电影, 电视剧在线, 高清视频, 海量资源, 影视娱乐'
        }
      ],
    };
  },
  data() {
    return {
      current: 0,
      hotMoviesData: [],
      dyData: [],
      dmData: [],
      dsjData: [],
      zyData: [],
      searchPop: false,
      banner: [],
      hotData: [],
      historyData: localStorage.getItem('history') ? JSON.parse(localStorage.getItem('history')).reverse() : [],
    }
  },
  mounted() {
    // banner 
    this.$http('/api/advertisement/list', {
      params: {
        vod_level: 9,
      },
    }).then((res) => {
      this.banner = res;
    })
    this.init();


  },
  methods: {
    async init() {
      await this.$http('/api/movies/list', {
        params: {
          pageSize: 30,
          vod_to_index: 1,
        },
      }).then((res) => {
        this.dsjData = res.rows;
      });
    },
    onChange(index) {
      this.current = index;
    },
    navBack() {
      console.log(1)
      this.searchPop = false;
    },
    goDetails(id) {
      this.$router.push({
        path: '/detail?id=' + id,
      })
    },
    rightClick() {
      this.$router.push({
        path: '/history',
      })
    },
  }
}
</script>

<style lang="less">
.hot-broadcast {
  padding: 0 10px;
}

.home-banner {
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 10px;

  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
  }

  .banner-txt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    background: rgba(0, 0, 0, .4);
    color: #fff;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
  }
}

.banner {
  height: 150px;

  .item {
    background: #fff;
    border-radius: 10px;
  }
}
</style>
