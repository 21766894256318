import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button, NavBar, Icon, Swipe, SwipeItem, Lazyload, Popup, Toast,NoticeBar,Search, ShareSheet,  } from 'vant';
import VueMeta from 'vue-meta';
import './registerServiceWorker'
import VueClipboard from "vue-clipboard2";
import 'vant/lib/index.css';
import 'ant-design-vue/dist/antd.css';
import {  Table, Input, Modal, Form ,FormItem, Radio } from 'ant-design-vue';
import Nav from '@/components/NavBar.vue';
import card from '@/components/card.vue';
import MovieList from '@/components/MovieList.vue';
import image from '@/components/image.vue';
import http from '@/config/http'
// import '@/less/base.less';
Vue.use(Radio);
Vue.use(Form);
Vue.use(Modal);
Vue.use(Input);
Vue.use(Table);
Vue.use(VueMeta);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ShareSheet);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Lazyload, {
  error: require('@/assets/img-error.png'),
});
Vue.use(NoticeBar);
// 复制到粘帖板功能
Vue.use(VueClipboard);
Vue.component("Nav", Nav);
Vue.component("Card", card);
Vue.component("MovieList", MovieList);
Vue.component("LayImage", image);

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$toast = Toast;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
