<template>
    <div class="header-box">
        <div class="header-search-box">
            <div class="left-box"></div>
            <div class="center-box" @click="inputClick">
                <van-icon class="search-icon" name="search" size="15" />
                <input class="search-input" ref="searchInput" v-model="inputVal" :readonly="readonly" type="text"
                    placeholder="搜索影片名称" @input="inputChange" @keyup.enter="handleEnter" return-key-type="搜索">
                <van-icon class="cross" name="cross" v-if="inputVal.length > 0" @click="clearInputVal" />
                <div class="search-btn" @click="handleEnter">
                    <van-icon class="" name="video-o" size="18" />
                </div>
            </div>
            <div class="right-box" @click="rightClick">
                <van-icon :name="rightIcon" v-if="rightIcon" size="18" color="#333" />
                <div>{{ rightTxt }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        readonly: {
            typeof: 'boolean',
            default: false,
        },
        rightTxt: {
            typeof: 'string',
            default: '',
        },
        rightIcon: {
            typeof: 'string',
            default: '',
        },
        value: {
            typeof: 'string',
            default: '',
        },
        focus: {
            typeof: 'boolean',
            default: false,
        },
    },
    data() {
        return {
            inputVal: ''
        }
    },
    watch: {
        value(e) {
            this.inputVal = e;
        },
    },
    activated() {
        if (this.focus) {
            this.$nextTick(() => {
             this.$refs.searchInput.focus();
            //  window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth' // 可选，平滑滚动
            // });
            });
        }
    },
    mounted() {
    },
    methods: {
        clearInputVal() {
            this.inputVal = '';
            this.$emit('inputChange', this.inputVal)
        },
        inputChange(e) {
            this.$emit('inputChange', this.inputVal)
        },
        rightClick() {
            this.$emit('rightClick');
        },
        inputClick() {
            this.$emit('inputClick')
        },
        handleEnter(e) {
            this.$emit('handleEnter', this.inputVal)
        }
    },
}
</script>

<style lang="less" scoped>
.search-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    color: #fff;
    background: @b-color;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.right-box {
    width: 40px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #898989;
}

.center-box {
    position: relative;
    flex: 1;

    .cross {
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
    }

    .search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
    }
}

.header-search-box {
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background: #fff;
    z-index: 8;
    max-width: 750px;
}
.header-box{
    height: 70px;
}
.search-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 10px;
    border-radius: 30px;
    padding-left: 40px;
    box-sizing: border-box;
    background: #f3f3f3;
}
</style>