<template>
	<div class="card-box">
		<div class="title-box">
			<div class="left">
				<div class="item" @click="change(index)" :class="active == index ? 'active' : ''" v-for="(item, index) in title" :key="index">{{item}}</div>
				<div class="smll-txt" v-if="smll" @click="smllClick">{{smll}}<van-icon name="arrow" /></div>
			</div>
			<div class="right">
				<div v-if="rightType == 'movie'" @click="changePlayer">切换节点<van-icon style="margin-left: 5px;" name="arrow-down" /></div>
				<div v-else-if="rightType == 'more'" @click="$router.push('/history')">更多</div>
			</div>
		</div>
		
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: ['title', 'rightType', 'cardIndex', 'smll'],
		data() {
			return {
				active: 0,
			};
		},
		watch: {
			cardIndex(val) {
				this.active = val;
			},
		},
		methods: {
			smllClick() {
				this.$emit('smllClick')
			},
			change(i) {
				this.active = i;
				this.$emit('change', i)
			},
			changePlayer() {
				this.$emit('rightClick')
			},
		},
	}
</script>

<style lang="less">
	.smll-txt{
		font-size: 12px;
		color: #989898;
	}
	.title-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		font-size: 14px;
		.left{
			display: flex;
			align-items: center;
			.item{
				margin-right: 10px;
				color: #656565;
				&.active{
					color: #000;
					font-weight: bold;
					font-size: 14px;
				}
			}
		}
		.right{
			color: #959595;
		}
	}
	.card-box{
		background: #fff;
		border-radius: 10px;
		padding: 10px;
		margin-top: 10px;
	}
</style>
